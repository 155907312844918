<template>
  <div class="container">
    <header-bar></header-bar>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">
        <i @click="goBack" class="el-icon-back">返回</i>
      </el-breadcrumb-item>
      <el-breadcrumb-item>资讯预览</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="hh_con_wrap">
      <div class="hh_con common_inner">
        <!-- <img class="hh_img" :src="infoData.imgUrl" alt="" /> -->
        <p class="hh_title">
          {{ infoData.newsTitle }}
        </p>
        <p class="hh_time">{{ infoData.updateTime }}</p>
        <div class="hh_content_wrap">
          <div class="hh_content" v-html="infoData.newsText"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import API from "@/apis/backStageEndAPI/consultManageAPI";
export default {
  name: "previewConsult",
  components: {
    headerBar,
  },
  data() {
    return {
      id: "",
      infoData: {},
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getConsultDetail(this.id);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getConsultDetail(id) {
      API.detail(id).then((res) => {
        this.infoData = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .hh_con_wrap {
    margin-top: 30px;
    width: 100%;
    .hh_con {
      background: #ffff !important;
      padding-bottom: 10px;
      .hh_title {
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }
      .hh_time {
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .hh_content_wrap {
        margin: 20px auto;
        width: 90%;
        border-top: 1px dotted #eaeaea;
        .hh_content {
          padding: 20px 0;
          color: #333333;
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
